import { memo, useCallback } from 'react';
import { Container, Button, Typography, Box, List, ListItem, ListItemText, ListItemIcon, Stack, useTheme } from '@mui/material'
import { pipe, applyTo } from 'ramda';
import { useAuth0 } from "@auth0/auth0-react";
import { propTypes, defaultProps, displayName } from 'lib/react';
import BoltIcon from '@mui/icons-material/Bolt';
import BackgroundImageMask from 'components/BackgroundImageMask';
import { Helmet } from "react-helmet";
import useMediaQuery from '@mui/material/useMediaQuery';
import bg from './bg.png'
import bg2 from './bg2.png'
import logo from './one-moto-logo.png'
import Image from 'components/Image';
import styled from 'styled-components';

const OneMotoContainer = styled(Container)`
    position: relative;
    width: 100%;
    height: 100%;
      
    @media(min-width: 768px) {
        margin-left: 0 !important;
        &::after{
            content: "";
            background: #000;
            opacity: 0.3;
            top: 0;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: -5;
        }
    }
`;

export default applyTo(() => {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

    const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();

    const handleLoginClick = useCallback(() => {
        loginWithRedirect()
    }, [loginWithRedirect])


    const handleCreateAccountClick = useCallback(() => {
        loginWithRedirect({ screen_hint: 'signup' })
    }, [loginWithRedirect])

    if (isLoading) return null;

    return (
        <>
            <Helmet>
                <title>Rally Moto | The One Moto Show</title>
            </Helmet>
            {
                isAuthenticated ?
                    (
                        <BackgroundImageMask src={bg2}>
                            <OneMotoContainer
                                maxWidth={false}
                                sx={{ height: '100%', width: '100%', maxWidth: 511 }}>
                                <Stack
                                    sx={{ height: '100%' }}
                                    justifyContent="center"
                                    spacing={2}>
                                    <Stack justifyContent="space-evenly" sx={{ height: '100%', maxWidth: 350, margin: 'auto' }}>
                                        <Box>
                                            <Image src={logo} alt="the one moto show logo" paddingBottom={3} sx={!isDesktop && { display: 'block', margin: 'auto' }} />
                                            <Typography variant='h2' color="common.white" paddingBottom={4}>{"You're in!"}</Typography>
                                            <Typography variant='h6' color="common.white" sx={{ maxWidth: 300 }}>
                                                {/* Purchase your tickets below and  */} Stay tuned —  we’ll send you an update as soon as the agenda is live.
                                            </Typography>
                                            {isDesktop && (
                                                <Stack spacing={1} paddingTop={2}>
                                                    {/* <Button variant='white' component='a' to="https://the1moto.com" sx={{ marginBottom: 2 }}>Purchase Tickets</Button> */}
                                                    <Typography variant='body1' color="common.white" textAlign={isDesktop ? 'left' : "center"} paddingBottom={4}>
                                                        Return to <Typography component="a" href="https://the1moto.com" color="common.white" sx={{ textDecoration: "underline" }}>the1moto.com</Typography>
                                                    </Typography>
                                                    <Typography variant='body2' textAlign={'left'} position='fixed' sx={{ bottom: 20 }}>
                                                        Powered by <Typography component="a" href="https://letsrally.io" target="_blank" sx={{ color: '#828284', textDecoration: "underline" }}>Rally Moto</Typography>
                                                    </Typography>
                                                </Stack>
                                            )}
                                        </Box>
                                    </Stack>
                                    {!isDesktop && (
                                        <Stack spacing={1} paddingBottom={2}>
                                            {/* <Button variant='white' component='a' to="https://the1moto.com" sx={{ margin: 'auto', marginBottom: 2, width: '100%' }}>Purchase Tickets</Button> */}
                                            <Typography variant='body1' color="common.white" textAlign={isDesktop ? 'left' : "center"} paddingBottom={4}>
                                                Return to <Typography component="a" href="https://the1moto.com" color="common.white" sx={{ textDecoration: "underline" }}>the1moto.com</Typography>
                                            </Typography>
                                            <Typography variant='body2' textAlign={isDesktop ? 'left' : "center"}>
                                                Powered by <Typography component="a" href="https://letsrally.io" target="_blank" sx={{ color: '#828284', textDecoration: "underline" }}>Rally Moto</Typography>
                                            </Typography>
                                        </Stack>
                                    )}
                                </Stack>
                            </OneMotoContainer>
                        </BackgroundImageMask>
                    ) : (
                        <BackgroundImageMask src={bg}>
                            <OneMotoContainer maxWidth={false} sx={{ height: '100%', width: '100%', maxWidth: 511 }}>
                                <Stack
                                    justifyContent="space-evenly"
                                    spacing={2}
                                    sx={{ height: '100%' }}>
                                    <Stack justifyContent="space-evenly" sx={{ height: '100%', maxWidth: 350, margin: 'auto' }}>
                                        <Box>
                                            <Image src={logo} alt="the one moto show logo" paddingBottom={3} sx={!isDesktop && { display: 'block', margin: 'auto' }} />
                                            <Typography variant='h2' color="common.white" paddingBottom={3}>Join The <br />One Moto Show Community</Typography>
                                            <List>
                                                <ListItem disablePadding>
                                                    <ListItemIcon>
                                                        <BoltIcon color="yellow" />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Receive a notification when the show agenda is available" color='secondary' sx={{ color: theme.palette.common.white }} />
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemIcon>
                                                        <BoltIcon color="yellow" />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Join the conversation as soon as the community launches" color='white' sx={{ color: theme.palette.common.white }} />
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemIcon>
                                                        <BoltIcon color="yellow" />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Secure your username on the new platform" sx={{ color: theme.palette.common.white }} />
                                                </ListItem>
                                            </List>
                                            {isDesktop && (
                                                <Stack spacing={1} paddingTop={2}>
                                                    <Button variant='white' onClick={handleCreateAccountClick} sx={{ marginBottom: 2 }}>Create Account</Button>
                                                    <Typography variant='body2' color="common.white" textAlign={isDesktop ? 'left' : "center"} paddingBottom={2}>
                                                        Already have an account? <Typography component="span" onClick={handleLoginClick} sx={{ textDecoration: "underline", color: '#B79B59', cursor: 'pointer' }}>Sign In</Typography>
                                                    </Typography>
                                                    <Typography variant='body2' textAlign={isDesktop ? 'left' : "center"} paddingBottom={2}>
                                                        You’re joining the One Motorcycle show community on the <Typography component="a" href="https://letsrally.io" target="_blank" sx={{ color: '#828284', textDecoration: "underline" }}>Rally Moto</Typography> app
                                                    </Typography>
                                                </Stack>
                                            )}
                                        </Box>
                                    </Stack>
                                    {!isDesktop && (
                                        <Stack spacing={1} paddingBottom={2}>
                                            <Button variant='white' onClick={handleCreateAccountClick} sx={{ margin: 'auto', marginBottom: 2, width: '100%' }}>Create Account</Button>
                                            <Typography variant='body2' color="common.white" textAlign={isDesktop ? 'left' : "center"} paddingBottom={2}>
                                                Already have an account? <Typography component="span" onClick={handleLoginClick} sx={{ textDecoration: "underline", color: '#B79B59', cursor: 'pointer' }}>Sign In</Typography>
                                            </Typography>
                                            <Typography variant='body2' textAlign={isDesktop ? 'left' : "center"} maxWidth={350} paddingBottom={2} sx={{ margin: 'auto !important' }}>
                                                You’re joining the One Motorcycle show community on the <Typography component="a" href="https://letsrally.io" target="_blank" sx={{ color: '#828284', textDecoration: "underline" }}>Rally Moto</Typography> app
                                            </Typography>
                                        </Stack>
                                    )}
                                </Stack>
                            </OneMotoContainer>
                        </BackgroundImageMask>
                    )
            }

        </>
    );
}, pipe(
    propTypes({}),
    defaultProps({}),
    displayName('OneMotoShow'),
    memo,
));
