import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import config from 'config';

export const publicClient = new ApolloClient({
    uri: `${config.API_HOST}/public/graphql`,
    cache: new InMemoryCache(),
});

const httpLink = createHttpLink({
    uri: `${config.API_HOST}/graphql`,
});

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem('token');
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
        }
    }
});

export const privateClient = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
});