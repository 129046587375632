import React, { memo } from 'react';
import { Box } from '@mui/material';
import { pipe, applyTo } from 'ramda';
import PropTypes from 'prop-types';
import { propTypes, defaultProps, displayName } from 'lib/react';
import styled from 'styled-components';

const BackgroundImage = styled(Box)`
&::before{
  content: "";
  background-color: black;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -10;
  height: 100vh;
}
&::after{
  content: "";
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -10;
}
`;

export default applyTo(({ children, src }) => {
  return (
    <BackgroundImage sx={{ height: '100vh', width: '100vw' }} src={src}>
      {children}
    </BackgroundImage>
  );
}, pipe(
  propTypes({
    children: PropTypes.node,
  }),
  defaultProps({
    children: null,
  }),
  displayName('BackgroundImageMask'),
  memo,
));
