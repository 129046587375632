import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { applyTo, pipe } from 'ramda';
import { propTypes, defaultProps, displayName } from 'lib/react';
import { Box } from '@mui/material'

export default applyTo(({ src, alt, sx, ...props }) => {
    return (
        <Box
            {...props}
            component="img"
            sx={sx}
            alt={alt}
            src={src}
        />
    );
}, pipe(
    propTypes({
        src: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired,
        sx: PropTypes.shape({})
    }),
    defaultProps({
        sx: {},
    }),
    displayName('Image'),
    memo,
));
