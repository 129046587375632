import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { applyTo, pipe } from 'ramda';

export const theme = createTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#000',
            light: "#000",
            contrastText: '#fff',
        },
        yellow: {
            main: '#B79B59',
            contrastText: '#fff',
        },
    },

    typography: {
        fontFamily: [
            "Helvetica",
            'sans-serif',
        ].join(','),
        h1: {
            fontFamily: 'Helvetica',
            fontSize: 70,
        },
        h2: {
            fontFamily: 'GT-America-Regular',
            fontSize: 42,
        },
        // h3: {
        //     fontFamily: 'GT-America-Standard-Medium',
        // },
        // h4: {
        //     fontFamily: 'GT-America-Standard-Medium',
        // },
        // h5: {
        //     fontFamily: 'GT-America-Standard-Medium',
        // },
        // h6: {
        //     fontFamily: 'GT-America-Standard-Medium',
        // },
        body2: {
            fontSize: 16,
            color: '#828284',
        },
        subtitle2: {
            fontSize: 12,
        }
    },

    components: {
        // https://mui.com/material-ui/customization/theme-components/#global-style-overrides
        // component overrides here
        MuiButton: {
            variants: [
                {
                    props: { variant: 'white' },
                    style: {
                        background: '#fff',
                        color: '#000',
                        "&:hover": {
                            backgroundColor: "#f6f6f6"
                        },
                        "&:disabled": {
                            backgroundColor: "#f6f6f6"
                        },
                    }
                },
                {
                    props: { variant: 'black' },
                    style: {
                        background: '#000',
                        color: '#fff',
                        "&:hover": {
                            backgroundColor: "#f6f6f6"
                        },
                        "&:disabled": {
                            backgroundColor: "#808080",
                            color: '#f6f6f6'
                        },
                    }
                }
            ],
            styleOverrides: {
                root: {
                    height: 60,
                    maxWidth: 350,
                    fontFamily: "GT-America-Medium",
                    textTransform: 'none',
                    fontSize: 16,
                },
            },
        },

        MuiContainer: {
            styleOverrides: {
                root: {
                    paddingLeft: 25,
                    paddingRight: 25,
                },
            },
        },

        MuiAppBar: {
            styleOverrides: {
                root: {
                    height: 70,
                },
            },
        },

        MuiToolbar: {
            styleOverrides: {
                root: {
                    height: 70,
                    paddingLeft: 50,
                    paddingRight: 50,
                },
            },
        },

        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    minWidth: 30,
                }
            }
        },

        MuiDrawer: {
            variants: [
                {
                    props: { variant: 'signup' },
                    style: {
                        '.MuiDrawer-paper': {
                            borderTopRightRadius: 30,
                            borderTopLeftRadius: 30,
                        }
                    }
                },
            ],
        }
    },
});

export default applyTo(theme, pipe(
    createTheme,
    responsiveFontSizes
))
