import { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { applyTo, pipe } from 'ramda';
import { propTypes, defaultProps, displayName } from 'lib/react';

export default applyTo(({ to }) => {
    useEffect(() => {
        window.location.href = to;
    })
    return null;
}, pipe(
    propTypes({
        to: PropTypes.string.isRequired,
    }),
    defaultProps({}),
    displayName('ExternalRedirect'),
    memo,
));
