import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { applyTo, pipe } from 'ramda';
import { CssBaseline } from '@mui/material';

import { propTypes, defaultProps, displayName } from 'lib/react';

import styles from './Layout.module.scss';
// import Header from './components/Header';
// import Footer from './components/Footer';
// import { useAuth0 } from '@auth0/auth0-react';

export default applyTo(({
  className,
  children,
}) => {
  // const { isAuthenticated } = useAuth0();
  return (
    <div data-testid="site" className={cx(styles.site, className)}>
      <CssBaseline />
      {/* {isAuthenticated && <Header className={styles.header} />} */}
      <main className={styles.main} id='main'>{children}</main>
      {/* <Footer className={styles.footer} /> */}
    </div>
  )
}, pipe(
  propTypes({
    children: PropTypes.node,
    className: PropTypes.string,
  }),
  defaultProps({
    children: null,
    className: null,
  }),
  displayName('Layout'),
  memo,
));
