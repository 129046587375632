import { memo } from 'react';
import { pipe, applyTo } from 'ramda';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from 'lib/theme'
import { publicClient } from 'lib/apollo';
import { ApolloProvider } from '@apollo/client';
import config from 'config';
import { propTypes, defaultProps, displayName } from 'lib/react';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
} from "react-router-dom";
import { Auth0Provider, } from "@auth0/auth0-react";
import OneMotoShow from 'views/OneMotoShow';
import Login from 'views/Login';
import Logout from 'views/Logout';
// import Profile from 'views/Profile';
import ExternalRedirect from 'components/ExternalRedirect';
import PageViewAnalytics from 'components/PageViewAnalytics';
import Layout from 'components/Layout';

const onRedirectCallback = (appState) => {
    window.location.href = appState?.returnTo || '/'
}

export default applyTo(() => {
    return (
        <ThemeProvider theme={theme}>
            <Router>
                {/* default to public client - override with private client when needed */}
                <Auth0Provider
                    domain={config.AUTH0_DOMAIN}
                    clientId={config.AUTH0_CLIENT_ID}
                    redirectUri={typeof window !== 'undefined' && `${window.location.origin}/callback`}
                    cacheLocation="localstorage"
                    onRedirectCallback={onRedirectCallback}>
                    <ApolloProvider client={publicClient}>
                        <PageViewAnalytics>
                            <Layout>
                                <Routes>
                                    <Route exact path="/" element={<Navigate to="/the1moto" />} />

                                    <Route exact path="/the1moto" element={<OneMotoShow />} />
                                    {/* <Route exact path="/profile" element={<Profile />} /> */}

                                    {/* Auth Routes */}
                                    <Route exact path="/login" element={<Login />} />
                                    <Route exact path="/logout" element={<Logout />} />
                                    {/* NOTE: we need the callback route here even though it does nothing due to the / -> /the1moto redirects */}
                                    <Route exact path="/callback" element={null} />

                                    {/* TODO: added these stubs when setting up google oauth which requires them */}
                                    <Route exact path="/privacy" element={<ExternalRedirect to="https://www.notion.so/rallymotoclub/Rally-Motorcycle-Club-Privacy-Policy-56a2495551554bfe956e294d5cd62ef7" />} />
                                    <Route exact path="/terms" element={<ExternalRedirect to="https://www.notion.so/rallymotoclub/Rally-Moto-Club-Terms-of-Service-1001fbccd4e2419d80af4cd65f30c514" />} />

                                    {/* 404 */}
                                    <Route path="*" element={<Navigate to="/" />} />
                                </Routes>
                            </Layout>
                        </PageViewAnalytics>
                    </ApolloProvider>
                </Auth0Provider>
            </Router>
        </ThemeProvider>
    );
}, pipe(
    propTypes({}),
    defaultProps({}),
    displayName('App'),
    memo,
));
